import React from 'react';

import './App.css';
import Portfolio from './Portfolio';

const App = () => (
  <>
    <Portfolio />
  </>
);

export default App;
