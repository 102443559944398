import * as React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { SvgIcon } from '@material-ui/core';

const Strava = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 46 10.336" {...props}>
      <path
        id="Strava"
        d="M29.639,19.832,25.33,28.348l-1.414-2.086a2.9,2.9,0,0,0,1.15-.963,2.56,2.56,0,0,0,.422-1.492A2.958,2.958,0,0,0,25.3,22.74a2.46,2.46,0,0,0-.528-.826,2.922,2.922,0,0,0-1.1-.672A5.264,5.264,0,0,0,21.912,21H18v8h2.662V26.7h.57l1.5,2.3H27.76l1.879-3.713L31.518,29h2.763l-4.642-9.168Zm13.718,0L38.707,29h2.766l1.884-3.713L45.237,29H48l-4.643-9.168ZM32,21l4.422,9.168L40.842,21H38.21l-1.79,3.71L34.634,21ZM5.828,21a4.8,4.8,0,0,0-1.45.2,3.112,3.112,0,0,0-1.054.554,2.34,2.34,0,0,0-.648.834,2.4,2.4,0,0,0-.223,1.027,1.869,1.869,0,0,0,.276,1.028,2.225,2.225,0,0,0,.732.7,4.48,4.48,0,0,0,1.055.453c.407.12.835.223,1.28.312a4.141,4.141,0,0,1,.872.233.323.323,0,0,1,.23.277.272.272,0,0,1-.158.242,1.124,1.124,0,0,1-.525.086,5.052,5.052,0,0,1-1.426-.21,4.24,4.24,0,0,1-1.365-.7L2,27.687a5.068,5.068,0,0,0,1.814.983A7.641,7.641,0,0,0,6.1,29a5.289,5.289,0,0,0,1.46-.17,3.388,3.388,0,0,0,1.1-.514,2.377,2.377,0,0,0,.707-.828,2.35,2.35,0,0,0,.242-1.14,1.93,1.93,0,0,0-.242-.973,2.153,2.153,0,0,0-.695-.7,4.175,4.175,0,0,0-1.057-.483,12.221,12.221,0,0,0-1.39-.32,3.779,3.779,0,0,1-.844-.23.321.321,0,0,1-.217-.268.26.26,0,0,1,.143-.23.837.837,0,0,1,.437-.086,4.25,4.25,0,0,1,1.191.183,4.055,4.055,0,0,1,1.2.582l1.3-1.754a4.882,4.882,0,0,0-1.582-.8A6.965,6.965,0,0,0,5.829,21Zm3.79,0v2.26h2.3V29h2.664V23.264h2.387V21H9.617Zm11.068,2.273h1.16a1.268,1.268,0,0,1,.736.192.663.663,0,0,1,.275.582.719.719,0,0,1-.275.594,1.525,1.525,0,0,1-.768.24l-1.128-.01Z"
        transform="translate(-2 -19.832)"
      />
    </SvgIcon>
  );
};

export default Strava;
